import type { OnInit } from '@angular/core';
import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash-es';

import type { Image } from '@core-mkt/interfaces/image';
import type { PageDataResponse } from '@core-mkt/resolvers/page-data/page-data-response';
import type { Menu } from './header';
import { newHeaderData } from './header-data';
import { stateList } from './state-nav-data';

@Component({
  selector: 'ace-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @HostBinding('class.sticky-header') headerSticky = false;
  resolverData: PageDataResponse;
  hideNav = false;
  showDefaultNav = true;
  secondaryLogo: Image;
  menuData: Menu = {
    state: 'Select State',
    showInPersonLessons: false,
    menuItems: newHeaderData.default.menuItems,
  };

  constructor(private route: ActivatedRoute) {
    if (this.route.snapshot) {
      this.resolverData = cloneDeep(this.route.snapshot.data.pageData);
    }
  }

  ngOnInit(): void {
    if (this.resolverData && this.resolverData.craftData && this.resolverData.craftData?.headerData) {
      this.menuData.showInPersonLessons = this.resolverData.craftData.headerData.hasInPersonLessons;
      this.hideNav = this.resolverData.craftData.headerData.hideNav;
      //needs to be added to the graphql query
      this.headerSticky = this.resolverData.craftData.headerData.isNavSticky;
      const additionalHeaderLogo = this.resolverData.craftData.headerData?.additionalHeaderLogo;
      if (additionalHeaderLogo && additionalHeaderLogo.length > 0) {
        this.secondaryLogo = additionalHeaderLogo[0];
      }
      if (this.resolverData.craftData.headerData?.pageState) {
        const stateData = stateList.find(
          (state) => state.href === `/${this.resolverData.craftData.headerData.pageState}/`,
        );
        this.menuData.state = stateData?.text || 'Select State';
        this.menuData.stateUrl = stateData?.href || '';
      }
    } else {
      this.menuData.state = 'Select State';
    }
    if (this.hideNav) {
      this.showDefaultNav = false;
    }
  }
}
