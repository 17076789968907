import { InsertableModule } from '@aceable/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
//Component Modules
import { AccordionModule } from '@core-mkt/shared/components/accordion/accordion.module';
import { AmbassadorInviteModule } from '@core-mkt/shared/components/ambassador-invite/amssador-invite.module';
import { AmbassadorReferralModule } from '@core-mkt/shared/components/ambassador-referral/ambassador-referral.module';
import { AnchorButtonModule } from '@core-mkt/shared/components/anchor-button/anchor-button.module';
import { AnchorModule } from '@core-mkt/shared/components/anchor/anchor.module';
import { BannerModule } from '@core-mkt/shared/components/banner/banner.module';
import { BreadcrumbModule } from '@core-mkt/shared/components/breadcrumb/breadcrumb.module';
import { BundleModule } from '@core-mkt/shared/components/bundle/bundle.module';
import { CareerCenterModule } from '@core-mkt/shared/components/career-center/career-center.module';
import { CareersModule } from '@core-mkt/shared/components/careers/careers.module';
import { EmailFormModule } from '@core-mkt/shared/components/email-form/email-form.module';
import { FaqModule } from '@core-mkt/shared/components/faq/faq.module';
import { GladlyHelpCenterModule } from '@core-mkt/shared/components/gladly-help-center/gladly-help-center.module';
import { GraphicBannerModule } from '@core-mkt/shared/components/graphic-banner/graphic-banner.module';
import { HeroHomepageModule } from '@core-mkt/shared/components/hero-homepage/hero-homepage.module';
import { LayoutModule } from '@core-mkt/shared/components/layout/layout.module';
import { MultiColumnModule } from '@core-mkt/shared/components/multi-column/multi-column.module';
import { PriceSelectorModule } from '@core-mkt/shared/components/price-selector/price-selector.module';
import { ProductCardModule } from '@core-mkt/shared/components/product-card/product-card.module';
import { QuizWidgetModule } from '@core-mkt/shared/components/quiz-widget/quiz-widget.module';
import { ResourcesModule } from '@core-mkt/shared/components/resources/resources.module';
import { ReviewsModule } from '@core-mkt/shared/components/reviews/reviews.module';
import { SideProductPickerModule } from '@core-mkt/shared/components/side-product-picker/side-product-picker.module';
import { SliderCardModule } from '@core-mkt/shared/components/slider-card/slider-card.module';
import { SliderModule } from '@core-mkt/shared/components/slider/slider.module';
import { TopSectionModule } from '@core-mkt/shared/components/top-section/top-section.module';
import { TrustpilotModule } from '@core-mkt/shared/components/trustpilot/trustpilot.module';
import { HubspotModule } from '../../components/hubspot/hubspot.module';
import { LearnMoreModule } from '../../components/learn-more/learn-more.module';
import { RsaProductModule } from '../../components/rsa-product/rsa-product.module';
import { StandAloneCtaModule } from '../../components/stand-alone-cta/stand-alone-cta.module';
import { TabContentModule } from '../../components/tabbed-content/tabbed-content.module';
//Everything Else
import { FooterModule as AAFooterModule } from '@core-mkt/aa/src/app/components/footer/footer.module';
import { HeaderModule as AAHeader } from '@core-mkt/brands/aa/src/app/components/header/header.module';
import { FooterModule as AarpFooterModule } from '@core-mkt/brands/aarp/src/app/components/footer/footer.module';
import { HeaderModule as AarpHeader } from '@core-mkt/brands/aarp/src/app/components/header/header.module';
import { FooterModule } from '@core-mkt/brands/ace/src/app/components/footer/footer.module';
import { HeaderModule } from '@core-mkt/brands/ace/src/app/components/header/header.module';
import { FooterModule as DecFooterModule } from '@core-mkt/brands/dec/src/app/components/footer/footer.module';
import { HeaderModule as DecHeader } from '@core-mkt/brands/dec/src/app/components/header/header.module';
import { FooterModule as InsFooterModule } from '@core-mkt/brands/ins/src/app/components/footer/footer.module';
import { HeaderModule as InsHeader } from '@core-mkt/brands/ins/src/app/components/header/header.module';
import { FooterModule as MtgFooterModule } from '@core-mkt/brands/mtg/src/app/components/footer/footer.module';
import { HeaderModule as MtgHeader } from '@core-mkt/brands/mtg/src/app/components/header/header.module';
import { MarketingModule } from '@core-mkt/shared/components/marketing/marketing.module';
import { TemplateSingleComponent } from './template-component/template-single.component';
import { TemplateSingleTemplateRoutingModule } from './template-st-routing.module';

@NgModule({
  declarations: [TemplateSingleComponent],
  exports: [TemplateSingleComponent],
  imports: [
    //Components
    AccordionModule,
    AmbassadorReferralModule,
    AmbassadorInviteModule,
    AnchorModule,
    AnchorButtonModule,
    BreadcrumbModule,
    BundleModule,
    CareersModule,
    CareerCenterModule,
    EmailFormModule,
    FaqModule,
    GladlyHelpCenterModule,
    GraphicBannerModule,
    HeroHomepageModule,
    HubspotModule,
    LayoutModule,
    LearnMoreModule,
    MultiColumnModule,
    PriceSelectorModule,
    ProductCardModule,
    QuizWidgetModule,
    ResourcesModule,
    ReviewsModule,
    RsaProductModule,
    SliderModule,
    SliderCardModule,
    SideProductPickerModule,
    StandAloneCtaModule,
    TabContentModule,
    TopSectionModule,
    TrustpilotModule,
    //Everything else
    MarketingModule,
    BannerModule,
    CommonModule,
    InsertableModule,
    TemplateSingleTemplateRoutingModule,
    HeaderModule,
    FooterModule,
    AAHeader,
    AAFooterModule,
    DecHeader,
    DecFooterModule,
    AarpHeader,
    AarpFooterModule,
    InsHeader,
    InsFooterModule,
    MtgHeader,
    MtgFooterModule,
  ],
})
export class TemplateSingleModule {}
