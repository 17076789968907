import { ComponentRegistry } from '@aceable/core';
import { Injectable } from '@angular/core';
import { AccordionComponent } from '@core-mkt/shared/components/accordion/accordion/accordion.component';
import { AmbassadorInviteComponent } from '@core-mkt/shared/components/ambassador-invite/ambassador-invite/ambassador-invite.component';
import { AmbassadorReferralComponent } from '@core-mkt/shared/components/ambassador-referral/ambassador-referral/ambassador-referral.component';
import { AnchorButtonComponent } from '@core-mkt/shared/components/anchor-button/anchor-button/anchor-button.component';
import { AnchorComponent } from '@core-mkt/shared/components/anchor/anchor/anchor.component';
import { BreadcrumbComponent } from '@core-mkt/shared/components/breadcrumb/breadcrumb/breadcrumb.component';
import { BundleComponent } from '@core-mkt/shared/components/bundle/bundle/bundle.component';
import { CareerCenterComponent } from '@core-mkt/shared/components/career-center/career-center/career-center.component';
import { CareersComponent } from '@core-mkt/shared/components/careers/careers/careers.component';
import { EmailFormComponent } from '@core-mkt/shared/components/email-form/email-form/email-form.component';
import { FaqComponent } from '@core-mkt/shared/components/faq/faq/faq.component';
import { GladlyHelpCenterComponent } from '@core-mkt/shared/components/gladly-help-center/gladly-help-center/gladly-help-center.component';
import { GraphicBannerComponent } from '@core-mkt/shared/components/graphic-banner/graphic-banner/graphic-banner.component';
import { HeroHomepageComponent } from '@core-mkt/shared/components/hero-homepage/hero-homepage/hero-homepage.component';
import { HubspotComponent } from '@core-mkt/shared/components/hubspot/hubspot/hubspot.component';
import { LayoutComponent } from '@core-mkt/shared/components/layout/layout/layout.component';
import { LearnMoreComponent } from '@core-mkt/shared/components/learn-more/learn-more/learn-more.component';
import { MultiColumnComponent } from '@core-mkt/shared/components/multi-column/muti-column/multi-column.component';
import { PriceSelectorComponent } from '@core-mkt/shared/components/price-selector/price-selector/price-selector.component';
import { ProductCardComponent } from '@core-mkt/shared/components/product-card/product-card/product-card.component';
import { QuizWidgetComponent } from '@core-mkt/shared/components/quiz-widget/quiz-widget/quiz-widget.component';
import { ResourcesComponent } from '@core-mkt/shared/components/resources/resources/resources.component';
import { ReviewsComponent } from '@core-mkt/shared/components/reviews/reviews/reviews.component';
import { RsaProductComponent } from '@core-mkt/shared/components/rsa-product/rsa-product/rsa-product.component';
import { SideProductPickerComponent } from '@core-mkt/shared/components/side-product-picker/side-product-picker/side-product-picker.component';
import { SliderCardComponent } from '@core-mkt/shared/components/slider-card/slider-card/slider-card.component';
import { SliderComponent } from '@core-mkt/shared/components/slider/slider/slider.component';
import { StandAloneCtaComponent } from '@core-mkt/shared/components/stand-alone-cta/stand-alone-cta/stand-alone-cta.component';
import { StudyPlanBuilderComponent } from '@core-mkt/shared/components/study-plan-builder/study-plan-builder/study-plan-builder.component';
import { TabComponent } from '@core-mkt/shared/components/tabbed-content/tabbed-content/tabbed-content.component';
import { TopProductComponent } from '@core-mkt/shared/components/top-product/top-product/top-product.component';
import { TopSectionComponent } from '@core-mkt/shared/components/top-section/top-section/top-section.component';
import { TrustpilotComponent } from '@core-mkt/shared/components/trustpilot/trustpilot/trustpilot.component';

@Injectable({
  providedIn: 'root',
})
export class MktComponentRegistryService extends ComponentRegistry {
  constructor() {
    super();
    this.add('accordion', AccordionComponent);
    this.add('anchor', AnchorComponent);
    this.add('anchorButton', AnchorButtonComponent);
    this.add('ambassadorInvite', AmbassadorInviteComponent);
    this.add('ambassadorReferral', AmbassadorReferralComponent);
    this.add('breadcrumbs', BreadcrumbComponent);
    this.add('bundle', BundleComponent);
    this.add('careers', CareersComponent);
    this.add('careerCenter', CareerCenterComponent);
    this.add('emailForm', EmailFormComponent);
    this.add('aarpFaq', FaqComponent);
    this.add('gladlyHelpCenter', GladlyHelpCenterComponent);
    this.add('graphicBanner', GraphicBannerComponent);
    this.add('homeHero', HeroHomepageComponent);
    this.add('hubspot', HubspotComponent);
    this.add('layout', LayoutComponent);
    this.add('learnMore', LearnMoreComponent);
    this.add('multiColumn', MultiColumnComponent);
    this.add('aarpPriceSelector', PriceSelectorComponent);
    this.add('productBuilder', StudyPlanBuilderComponent);
    this.add('productCards', ProductCardComponent);
    this.add('externalWidget', QuizWidgetComponent);
    this.add('resources', ResourcesComponent);
    this.add('reviews', ReviewsComponent);
    this.add('rsaProduct', RsaProductComponent);
    this.add('sideProductPicker', SideProductPickerComponent);
    this.add('slider', SliderComponent);
    this.add('sliderCard', SliderCardComponent);
    this.add('standAloneCta', StandAloneCtaComponent);
    this.add('tabComponent', TabComponent);
    this.add('topBundle', TopProductComponent);
    this.add('topSectionWysiwyg', TopSectionComponent);
    this.add('trustpilot', TrustpilotComponent);
  }
}
