<div class="{{ theme?.root }}" id="rsaBundle">
  <div class="container">
    <div class="flex flex-col lg:flex-row items-center">
      <div class="rsa-content w-full lg:w-1/2 py-v-spacing sm:p-v-spacing">
        <div *ngIf="title" id="rsa-title" class="wysiwyg pb-4  mx-auto" [innerHTML]="title"></div>
        <div *ngIf="description" id="rsa-description" class="wysiwyg pb-4 mx-auto" [innerHTML]="description"></div>
        <span class="font-black leading-[27px]">{{ rsaTopListText }}</span>
        <ul id="rsa-bullets" class="grid grid-cols-2 pb-8 mx-auto flex flex-wrap w-full justify-start">
          <li *ngFor="let bullet of bullets; let last = last"
            class="sm:text-baselg w-auto {{ !last ? 'pb-3' : '' }} relative pl-8">
            <fa-icon [icon]="faCheck" class="text-vis-brand-1 fa-1x absolute left-0 top-0"> </fa-icon>
            {{ bullet.bulletItem }}
          </li>
        </ul>
        <div id="prices" *ngIf="product || hasCta"
          class="flex flex-col content-center mx-auto justify-start">
         <span class="pb-6 text-base font-normal leading-6 hidden lg:block">{{ rsaTopButtonText }}</span>
          <vision-button class="pt-6 sm:pt-0 sm:pr-v-spacing w-fit" defaultStyle="primary" *ngIf="hasCta"
            [data]="ctaButtonData"></vision-button>
        </div>
      </div>
      <div class="rsa-image w-full lg:w-1/2 py-v-spacing sm:p-v-spacing">
        <img
          class="mx-auto pb-6"
          [src]="rsaImageUrl"
          [alt]="rsaImageAltText"
          width="459"
          height="459"
        />
        <div *ngIf="rsaImageFooterTextShow" class="flex justify-center w-full">
          <span class="text-base font-normal leading-6 mx-auto">{{ rsaImageFooterText }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
