/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const HeroFragment = {
  Hero: gql`
    fragment HeroQuery on baseComponents_homeHero_BlockType {
      productPickerTable {
        ... on productPickerCategories_Category {
          productPickerTable {
            state
            productName
            productLink
            noFollow
            noReferrer
            modal
          }
        }
      }
      backgroundImage {
        url
      }
      buttonList {
        ... on buttonList_BlockType {
          buttonIcon {
            url
            ... on images_Asset {
              altText
            }
          }
          buttonLink
          buttonLinkNewTab
          buttonLinkNofollow
          buttonSubtitle
          buttonTitle
        }
      }
      heroContent
      heroImage {
        url
        ... on images_Asset {
          altText
        }
      }
      heroTitle
      imageAlignment
      lineBreak_content
      lineBreak_styles
      showImageOnMobile
      showTrustpilot
      styles
      typeHandle
    }
  `,
};
